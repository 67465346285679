import logo from './images/main_logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Website in development. Please come back later...
        </p>
        <a
          className="App-link"
          href="https://www.etsy.com/shop/tasteeshirts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Shop Now
        </a>
      </header>
    </div>
  );
}

export default App;
